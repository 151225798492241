@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, Verdana, Geneva, Tahoma, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modal-box {
    max-width: none !important;
}
